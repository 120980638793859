import React from 'react';
import { Link } from 'gatsby';

import type { PopupArgs } from '@customTypes/popup';
import { SubscriptionState } from '@customTypes/popup';

import ClosePopupIcon from '@images/icons/popup/ClosePopup.svg';
import { event } from '@analytics/gtm';
import { InputButton } from './InputButton';

import { subscribe } from '../../periodicaApi/emailSubscribe';

import styles from './NewPopup.module.scss';

export function Popup({ isOpen, onClose }: PopupArgs) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [subscriptionState, setSubscriptionState] = React.useState(SubscriptionState.NotSubscribed);
  const [text, setText] = React.useState('');
  const [done, setDone] = React.useState(false);
  const [error, setError] = React.useState<any>();
  const emailRef = React.useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const email = emailRef.current?.value;
    setIsLoading(true);
    if (text) {
      try {
        await subscribe(email, { popup: 'true' });
        setSubscriptionState(SubscriptionState.Subscribed);
        setDone(true);
        setText('');
        event('custom_subscribe_email_popup', {});
        window.localStorage.setItem('emailPopup_isSubscribed', 'true');
        if (window.localStorage.getItem('emailPopup_openIn')) {
          window.localStorage.removeItem('emailPopup_openIn');
        }
      } catch (err) {
        setError(err);
        setSubscriptionState(SubscriptionState.Error);
        console.error(err);
      }
    }
    setIsLoading(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  if (typeof document !== 'undefined') {
    document.addEventListener('keydown', handleKeyDown);
  }

  const renderContent = React.useCallback(() => {
    switch (subscriptionState) {
      case SubscriptionState.Error:
        return (
          <InputButton
            handleSubmit={handleSubmit}
            emailRef={emailRef}
            text={text}
            setText={setText}
            done={done}
            onClose={onClose}
          />
        );
      case SubscriptionState.Subscribed:
        return (
          <InputButton
            handleSubmit={handleSubmit}
            emailRef={emailRef}
            text={text}
            setText={setText}
            done={done}
            setDone={setDone}
            onClose={onClose}
          />
        );
      default:
        return (
          <InputButton
            handleSubmit={handleSubmit}
            emailRef={emailRef}
            text={text}
            setText={setText}
            onClose={onClose}
          />
        );
    }
    // TODO: не хватает зависимостей?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done, text]);

  if (isOpen) {
    return (
      <div className={styles.overlay} onClick={onClose} aria-hidden>
        <div
          className={styles.popupBlock}
          onClick={(e) => {
            e.stopPropagation();
          }}
          aria-hidden
        >
          <div className={styles.img} onClick={onClose} aria-hidden>
            <img src={ClosePopupIcon} alt="Закрыть" />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>
              <h1>Дарим скидку</h1>
              <p>
                на первый заказ, полезные материалы и специальные предложения — только для
                подписчиков рассылки
              </p>
            </div>
            <div className={styles.userBlock}>
              <div className={styles.form}>{renderContent()}</div>
              <div className={styles.description}>
                <div className={styles.text}>
                  Нажимая на кнопку “Подписаться”, вы соглашаетесь с нашей&nbsp;
                  <Link to="/privacy/">политикой конфиденциальности</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
