import React from 'react';
import cn from 'classnames';

import type { LabelArgs } from '@customTypes/labels';

import { Label } from './components/Label';

import styles from './Labels.module.scss';

interface LabelsInterface {
  labels: LabelArgs[];
  customClassName?: string;
}

export function Labels({ labels = [], customClassName }: LabelsInterface) {
  return (
    <div
      className={cn({
        [styles.labelsContainer]: true,
        ...(customClassName ? { [customClassName]: true } : {}),
      })}
    >
      {labels.length > 0 &&
        // eslint-disable-next-line array-callback-return
        labels.map((label) => {
          const { name, text = '', color = '' } = label;
          if (name) {
            switch (name) {
              case 'none':
                return;

              case 'newProduct':
              case 'newDesign':
              case 'preOrder':
              case 'soon':
              case 'sale':
              case 'special':
              case 'limited':
              case 'isUnavailable':
                return <Label key={`label__${name}`} labelName={name} />;

              default:
                return (
                  <Label
                    key={`label__${name}`}
                    labelName={name}
                    labelText={text}
                    labelColor={color}
                  />
                );
            }
          }
        })}
    </div>
  );
}
