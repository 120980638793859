import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import { productType } from '../prop-types/product';
import { ProductCard } from './ProductCard';
import styles from './Recommendations.module.scss';

export function Recommendations({ items }) {
  return (
    <div
      className={cn({
        [styles.items]: true,
        [styles.flexStub]: items.length < 4,
      })}
    >
      {items.map((product) => (
        <ProductCard product={product} className={styles.item} key={`product-${product.url}`} />
      ))}
    </div>
  );
}

Recommendations.propTypes = {
  items: propTypes.arrayOf(productType),
};

Recommendations.defaultProps = {
  items: [],
};
