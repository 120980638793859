import React from 'react';
import { Link } from 'gatsby';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import styles from './PostBlock.module.scss';

interface PostBlockArgs {
  title: string;
  description: string;
  image: IGatsbyImageData;
  url: string;
}

export function PostBlock({ title, description, image, url }: PostBlockArgs) {
  return (
    <Link to={url} className={styles.block}>
      <GatsbyImage alt={title} className={styles.image} image={image} />
      <div className={styles.metadata}>
        <h3 className={styles.postTitle}>{title}</h3>
        <p className={styles.postDescription}>{description}</p>
      </div>
    </Link>
  );
}
