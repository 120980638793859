import React from 'react';

import { useTopProducts } from '@hooks/useTopProducts';

import { ProductCard } from './ProductCard';

import styles from './ProductsTop.module.scss';

function ProductsTop() {
  const top = useTopProducts();
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Топ продуктов</h2>
      <div className={styles.products}>
        {top.map((item: any) => (
          <ProductCard
            product={item.product}
            className={styles.card}
            key={`${item.product.id}__TOP`}
            disableLabels
          />
        ))}
      </div>
    </div>
  );
}

export default ProductsTop;
