import React from 'react';
// @ts-ignore

export function SchemaOrgOrganization() {
  /* eslint-disable @typescript-eslint/naming-convention */
  return (
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Периодика',
        alternateName: 'Периодика Пресс',
        url: 'https://periodica.press',
        logo: 'https://periodica.press/static/assets/favicon/apple-touch-icon.png',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '+7-495-005-2174',
            contactType: 'customer service',
          },
        ],
        sameAs: ['http://www.facebook.com/periodicapress', 'http://instagram.com/periodicapress'],
      })}
    </script>
  );
  /* eslint-enable @typescript-eslint/naming-convention */
}
