import propTypes from 'prop-types';
import { gatsbyImageType } from './image';

export const serviceType = propTypes.shape({
  title: propTypes.string.isRequired,
  subtitle: propTypes.string,
  price: propTypes.string,
  info: propTypes.string,
  url: propTypes.string.isRequired,
  image: gatsbyImageType.isRequired,
  externalUrl: propTypes.bool.isRequired,
});
