import propTypes from 'prop-types';

export const imageType = propTypes.shape({
  publicURL: propTypes.string,
});

export const gatsbyImageType = propTypes.shape({
  localFile: propTypes.shape({
    publicUrl: propTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    localFile: propTypes.object,
  }),
});
