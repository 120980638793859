import React from 'react';
import Layout from '@components/Layouts/Layout';
import { ProductsRoot } from '@components/Blocks/ProductsRoot';
import MobileApps from '@components/Blocks/MobileApps';
import { Services } from '@components/Blocks/Services';
import { RecommendationsGroupped } from '@components/Blocks/RecommendationsGroupped';
import { SchemaOrgOrganization } from '@components/Layouts/SchemaOrg/SchemaOrgOrganization';
import { DesktopBanner, MobileBanner } from '@components/Blocks/Banners';
import SEO from '@components/Layouts/SEO';

import ProductsTop from '@components/Blocks/ProductsTop';
import { AboutProject } from '@components/Blocks/AboutProject';
import { Blog } from '@components/Blocks/Blog/Blog';
import { Popup } from '@components/newPopup';
import { PromoBlock } from '@components/Blocks/PromoBlock';
import { ReviewsList } from '@components/Blocks/ReviewsList';
import { event } from '@analytics/gtm';

export function Head() {
  return (
    <>
      <SEO title=" Фотокнига на заказ: изготовление и печать фотокниг в Москве с доставкой по России" />
      <SchemaOrgOrganization />
    </>
  );
}

function IndexPage() {
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const handleClosePopup = () => {
    const date = new Date();
    date.setDate(date.getDate() + 3);
    setIsPopupOpen(false);
    window.localStorage.setItem('emailPopup_openIn', `${date}`);
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const openIn = window.localStorage.getItem('emailPopup_openIn');
      const isSubscribed = window.localStorage.getItem('emailPopup_isSubscribed') || false;
      const currentDate = new Date();
      const openingDate = openIn ? new Date(openIn) : currentDate;
      const shouldOpenSubscriptionPopup = currentDate >= openingDate && !isSubscribed;

      if (shouldOpenSubscriptionPopup) {
        const timeout = setTimeout(() => {
          setIsPopupOpen(true);
          event('custom_subscribe_open_popup', {});
        }, 5000);
        return () => clearTimeout(timeout);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <DesktopBanner />
      <MobileBanner />
      <ProductsRoot />
      <ProductsTop />
      <Services header="Почему мы?" />
      <PromoBlock
        promoImage="szv-photo.jpg"
        promoTitle="Соберем фотокнигу за вас"
        promoText="Создавайте новые счастливые воспоминания, а наши дизайнеры позаботятся о том, чтобы сохранить их красиво"
        promoLink="/design-photobook"
      />
      <RecommendationsGroupped />
      {/* TODO: replace with PromoBlock */}
      <AboutProject />
      <Blog />
      <ReviewsList />
      <MobileApps />
      <Popup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </Layout>
  );
}

export default IndexPage;
