import React from 'react';
import { Link } from '@reach/router';

import arrow from '@images/icons/right-chevron.svg';
import { Review } from './Review';

import styles from './ReviewsList.module.scss';

export function ReviewsList() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.testimonialsTitleContainer}>
        <h2 className={styles.testimonialsTitle}>Отзывы</h2>
        <Link to="/review_clients/" className={styles.testimonialsMoreMob}>
          Все отзывы
          <img src={arrow} alt="arrow" />
        </Link>
        <Link to="/review_clients/" className={styles.testimonialsMore}>
          Читать все отзывы
          <img src={arrow} alt="arrow" />
        </Link>
      </div>
      <div className={styles.content}>
        <div className={styles.content__reviewsBlock}>
          <Review
            name="Аня Ильина"
            bookUrl="/products/photobooks/big-photobook-fabric-gray/"
            photo="Review1.jpeg"
          >
            <>
              <p>
                У меня в телефоне 90 тыс фотографий и блуждать в этих архивах становится все сложнее
                :)
              </p>{' '}
              <p>
                Решила собрать альбом про наш год. Удивилась, СКОЛЬКО всего было! Наше знакомство.
                Первые свидания. Как мы съездили в Африку, а после — сразу съехались. Внезапный
                отъезд и жизнь в Грузии.{' '}
              </p>
              <p>
                Бали и первая в жизни Жени съемка, которая (шок, но это правда) ему супер
                понравилась. Знакомство с моим папой и родными в Израиле. Когда дарила —
                расплакалась дважды.
              </p>
              <p>Хочу сделать традицией и фиксировать каждый год.</p>
            </>
          </Review>
          <Review
            name="Галина"
            bookUrl="/products/photobooks/big-photobook-fabric-darkgray/"
            photo="Review2.jpeg"
          >
            <>
              <p>
                Хранить воспоминания очень важно, а сохранить их красиво - для меня важно вдвойне,
                ведь наши воспоминания - ценный ресурс, из которого можно черпать силы.
              </p>
              <p>
                @periodicapress - не просто красиво, это непередаваемые эмоции, теплота тактильных
                ощущений, потрясающее качество бумаги и печати!
              </p>
              <p>
                Пересматривать любимые фотографии и листать семейную фотокнигу, что может быть
                лучше!
              </p>
            </>
          </Review>
          <Review
            name="Валерия"
            bookUrl="/products/photobooks/hardbook-photo-vertical/"
            photo="Review3.jpeg"
          >
            <>
              <p>
                Фотография, в своих лучших проявлениях, является сильным языком, который говорит на
                уровне эмоций. Это позволяет нам рассказывать свою историю и показывать другим людям
                мир, который мы создаем вокруг себя.{' '}
              </p>
              <p>
                Сегодня прекрасный, светлый праздник- день наших мамочек. В этот день хочется
                преумножать проявления заботы и тепла к ним. И даже будучи на расстоянии это до сих
                пор реально сделать.{' '}
              </p>
              <p>
                В последнее время я очень трепетно отношусь к сохранению фотоснимков. Хранить
                результат просто в виртуальной памяти-кощунство. Так и пришла ко мне идея подарка.
              </p>
              <p>
                Ребята из @periodicapress помогли воплотить мне ее в реальность. Мне кажется я
                взахлёб составляла этот альбом. Ожидания полностью оправдались. Даже больше.
              </p>
            </>
          </Review>
        </div>
      </div>
    </div>
  );
}
