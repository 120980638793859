import React from 'react';
import { Link } from 'gatsby';

import { usePosts } from '@hooks/usePosts';
import arrow from '@images/icons/right-chevron.svg';
import { PostBlock } from './PostBlock';

import styles from './Blog.module.scss';

export function Blog() {
  const posts = usePosts().slice(0, 3);
  return (
    <div className={styles.wrapper}>
      <div className={styles.blogTitleContainer}>
        <h2 className={styles.blogTitle}>Наш блог</h2>
        <Link to="/help" className={styles.blogMore}>
          Ещё
          <img src={arrow} alt="icon" />
        </Link>
      </div>
      <div className={styles.posts}>
        <div className={styles.postBlocks}>
          {posts.map((post: any) => (
            <PostBlock
              key={`PostBlock-${post.key}`}
              title={post.title}
              description={post.description}
              image={post.image.localFile.childImageSharp?.gatsbyImageData}
              url={post.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
