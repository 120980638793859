import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';

import { serviceType } from '../../prop-types/service';
import { useServices } from '../../../hooks/useServices';
import { ServiceBlock } from './ServiceBlock';

import styles from './Services.module.scss';

export function Services({ customServices, header }) {
  const services = useServices();
  return (
    <div
      className={cn({
        [styles.wrapper]: true,
      })}
    >
      <div className={styles.servicesTitleContainer}>
        {header && <h2 className={styles.servicesTitle}>{header}</h2>}
      </div>
      <div className={styles.services}>
        <div className={styles.serviceBlocks}>
          {(customServices || services).map((s) => (
            <ServiceBlock service={s} key={s.url} />
          ))}
        </div>
      </div>
    </div>
  );
}

Services.propTypes = {
  customServices: propTypes.oneOfType([propTypes.arrayOf(serviceType), propTypes.oneOf([null])]),
  header: propTypes.string,
};

Services.defaultProps = {
  customServices: null,
  header: '',
};
