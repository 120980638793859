import propTypes from 'prop-types';
import { gatsbyImageType } from './image';

export const productType = propTypes.shape({
  title: propTypes.string.isRequired,
  url: propTypes.string.isRequired,
  image: gatsbyImageType.isRequired,
  minPrice: propTypes.number,
  maxPrice: propTypes.number,
  realPrice: propTypes.number,
  salePrice: propTypes.number,
});
