import React from 'react';
import cn from 'classnames';

import styles from './Label.module.scss';

interface LabelInterface {
  labelName: string;
  labelText?: string;
  labelColor?: string;
}

export function Label({ labelName, labelText = '', labelColor = '' }: LabelInterface) {
  return (
    <div
      className={cn({
        [styles.label]: true,
        [styles[`label_${labelName}`]]: true,
      })}
      style={{ backgroundColor: labelColor || '' }}
    >
      {labelText || ''}
    </div>
  );
}
