import propTypes from 'prop-types';
import { imageType } from './image';

export const rootProductType = propTypes.shape({
  title: propTypes.string.isRequired,
  subtitle: propTypes.string,
  price: propTypes.string,
  url: propTypes.string.isRequired,
  image: imageType,
  newProduct: propTypes.bool,
  externalLink: propTypes.bool,
});
