import React from 'react';
import cn from 'classnames';
import Images from '@components/Images';
import iconImage from '@images/icons/image.svg';
import iconPencils from '@images/icons/pencils.svg';
import iconTabs from '@images/icons/tabs.svg';
import iconEmail from '@images/icons/email-icon.svg';
import styles from './MobileApps.module.scss';

interface TextBlockArgs {
  icon: string;
  text: string;
}

function TextBlock({ icon, text }: TextBlockArgs) {
  return (
    <div className={styles.block}>
      <img src={icon} alt="icon" />
      <p>{text}</p>
    </div>
  );
}

function Apps() {
  const title = 'Собирайте фотокниги, открытки и другие продукты в приложении ✨';
  return (
    <div className={styles.apps}>
      <h2 className={styles.titleMobile}>{title}</h2>
      <div className={styles.wrapper}>
        <Images src="mobile_apps.jpg" className={styles.photo} alt="iOS приложение Периодика" />
        <div className={styles.info}>
          <h2 className={styles.title}>{title}</h2>
          <TextBlock
            icon={iconPencils}
            text="Удобный конструктор: подсказки и интуитивно понятный интерфейс"
          />
          <TextBlock
            icon={iconImage}
            text="Возможность добавлять снимки из фотоплёнки телефона сразу в книгу 🙌🏻"
          />
          <TextBlock icon={iconTabs} text="Все продукты есть как на сайте, так и в приложении" />
          <TextBlock
            icon={iconEmail}
            text="Отправляем пуши редко и по делу: со специальными предложениями и анонсами новинок 😎"
          />
          <div className={styles.links}>
            <a
              title="iOS app"
              className={cn({
                [styles.icon]: true,
                [styles.iconIos]: true,
              })}
              target="_blank"
              rel="noopener nofollow noreferrer"
              href="https://app.appsflyer.com/id1005018803?pid=landing_page_periodica&c=index"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apps;
