import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useRootProducts } from '@hooks/useRootProducts';
import arrow from '@images/icons/right-arrow.svg';
import { event } from '@analytics';
import { isFieldPresentInStrapi } from '@utils/isFieldPresentInStrapi';
import { rootProductType } from '../../prop-types/root-product';
import { Labels } from '../Labels';
import { RubleSign } from '../RubleSign';

import styles from './ProductsRoot.module.scss';

function AllProductLink() {
  return (
    <Link
      to="/products"
      className={cn({
        [styles.item]: true,
        [styles.allProducts]: true,
      })}
    >
      <div
        className={cn({
          [styles.stub]: true,
          [styles.image]: true,
        })}
      />
      <div
        className={cn({
          [styles.metadata]: true,
          [styles.metadataCenter]: true,
        })}
      >
        <h3 className={styles.title}>Все продукты</h3>
        <img src={arrow} alt="перейти" />
      </div>
    </Link>
  );
}

function RootProduct({
  product: { title, subtitle, googleEvent, price, url, image, labels, externalLink },
}) {
  const handleProductClick = () => {
    if (isFieldPresentInStrapi(googleEvent)) {
      event(`event_site_${googleEvent}`);
    }
  };

  return !externalLink ? (
    <Link
      to={url}
      className={cn({
        [styles.item]: true,
      })}
      onClick={handleProductClick}
    >
      <GatsbyImage
        alt={title}
        imgStyle={{ objectPosition: 'right bottom' }}
        className={styles.image}
        image={image.localFile.childImageSharp.gatsbyImageData}
      />

      <div
        className={cn({
          [styles.metadata]: true,
        })}
      >
        <h3 className={styles.title}>{title}</h3>
        {subtitle && subtitle !== ' ' && <p className={styles.subtitle}>{subtitle}</p>}
        {price && (
          <div
            className={cn({
              [styles.price]: true,
              [styles.flex]: subtitle && subtitle !== ' ',
            })}
          >
            {price}
            &nbsp;
            <RubleSign />
          </div>
        )}
      </div>
      <Labels labels={labels} customClassName={styles.labelCustomContainer} />
    </Link>
  ) : (
    <a
      href={url}
      className={cn({
        [styles.item]: true,
      })}
      target="_blank"
      rel="noreferrer"
      onClick={handleProductClick}
    >
      <GatsbyImage
        alt={title}
        imgStyle={{ objectPosition: 'right bottom' }}
        className={styles.image}
        image={image.localFile.childImageSharp.gatsbyImageData}
      />
      <div
        className={cn({
          [styles.metadata]: true,
        })}
      >
        <h3 className={styles.title}>{title}</h3>
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        {price && (
          <div
            className={cn({
              [styles.price]: true,
              [styles.flex]: subtitle,
            })}
          >
            {price}
            &nbsp;
            <RubleSign />
          </div>
        )}
      </div>
    </a>
  );
}

export function ProductsRoot() {
  const products = useRootProducts();

  return (
    <>
      <div className={styles.wrapper}>
        <h1 className={styles.productsTitle}>Сохраняем ваши истории</h1>
      </div>
      <div className={styles.products}>
        {products.map((product, index) => {
          if (products.length > 4 && index === 4) {
            return (
              <React.Fragment key={`${product.url}-${product.id}`}>
                <AllProductLink />
                <RootProduct key={`${product.url}`} product={product} />
              </React.Fragment>
            );
          }
          return <RootProduct key={`${product.url}`} product={product} />;
        })}
      </div>
    </>
  );
}

RootProduct.propTypes = {
  product: rootProductType.isRequired,
};
